<template>
  <div class="main-content">
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="col"></div>

              <div class="col-auto d-flex">
                <select
                  name="select-route-type"
                  v-model="selectedRouteType"
                  class="custom-select mr-2"
                >
                  <option value="">All</option>
                  <option value="shared">Shared Routes</option>
                  <option value="corporate">Corporate Routes</option>
                  <option value="exclusive">Exclusive Routes</option>
                </select>

                <select
                  name="select-vehicle-type"
                  v-model="selectedVehicleType"
                  class="custom-select mr-2"
                >
                  <option value="">All</option>
                  <template v-if="vehicleTypes">
                    <option
                      :value="item.id"
                      v-for="item in vehicleTypes"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </template>
                </select>
                <div class='flex gap-4 items-center'>
                  <download-button
                    :downloading="downloadingReport"
                    label="Download Report"
                    @download="downloadReport()"
                  ></download-button>
                    <button class="btn btn-outline-primary btn-sm mr-2" @click='isEditable = !isEditable'>
                    {{isEditable ? 'Save' : 'Edit'}}
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <b-table
                striped
                hover
                selectable
                show-empty
                responsive
                :items="partners"
                :fields="fields"
                :current-page="currentPage"
                :busy="fetchingPartners"
              >
                <template #table-busy>
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="spinner-border" role="status"></div>
                    <p class="text-center mt-2"><strong>Loading...</strong></p>
                  </div>
                </template>

                <template #empty>
                  <p class="text-center text-secondary">No records available</p>
                </template>

                <template #cell(status)="data">
                  <span
                    class="text-white"
                    :class="`badge p-2 ${
                      data.item.status === 'active'
                        ? 'badge-success'
                        : data.item.status === 'suspended'
                        ? 'badge-danger'
                        : data.item.status === 'pending'
                        ? 'badge-warning'
                        : ''
                    }`"
                  >
                    {{ data.item.status }}
                  </span>
                </template>
                <template #cell(serialNumber)="data">
                  <p>
                    {{ data.index + 1 }}
                  </p>
                </template>
                <template #cell(name)="data">
                  <p>
                    {{ `${data.item.owner?.fname} ${data.item.owner?.lname}` }}
                  </p>
                  <p></p>
                </template>

                <template #cell(cost_of_supply)="data">
                  <div>
                    <vue-inline-text-editor
                      :value.sync="data.item.cost_of_supply"
                      placeholder="Enter amount"
                      :currency-symbol="data.item.currency"
                      @update="
                        updateRouteItineraryCostPrice(
                          data.item.driver_id,
                          data.item.vehicle_id,
                          data.item.id,
                          data.item.cost_of_supply
                        )
                      "
                      type="currency"
                    >
                      <span slot="edit-label">Edit</span>
                    </vue-inline-text-editor>
                  </div>
                </template>

                <template #cell(pricing_type)="data">
                  <div class="d-flex">
                    <select
                    :disabled='!isEditable'
                      @change="
                        updateRouteItinerary(
                          data.item.route_itinerary.id,
                          'pricing_type',
                          $event.target.value
                        )
                      "
                      type="select"
                      class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
                      v-model="data.item.route_itinerary.pricing_type"
                    >
                      <option disabled>Select Option</option>
                      <option
                        :value="item.value"
                        :key="index"
                        v-for="(item, index) in [
                          {
                            value: 'route_price_table_lookup',
                            label: 'Look Up From Pricing Table',
                          },
                          {
                            label: 'Auto Generated From Margin',
                            value: 'margin_value_generated',
                          },
                        ]"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </template>

                <template #cell(pricing_scheme)="data">
                  <div class="d-flex">
                    <select
                    :disabled='!isEditable'
                      @change="
                        updateRouteItinerary(
                          data.item.route_itinerary.id,
                          'pricing_scheme',
                          $event.target.value
                        )
                      "
                      type="select"
                      class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
                      v-model="data.item.route_itinerary.pricing_scheme"
                    >
                      <option disabled>Select Option</option>
                      <option
                        :value="item.value"
                        :key="index"
                        v-for="(item, index) in [
                          { label: 'per_vehicle', value: 'per_vehicle' },
                          { label: 'per_seat', value: 'per_seat' },
                        ]"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </template>

                <template #cell(pricing_margin)="data">
                  <div
                    class="d-flex"
                    v-if="
                      data.item.route_itinerary.pricing_type ===
                      'margin_value_generated'
                    "
                  >
                    <input
                    :disabled='!isEditable'
                      @change="
                        updateRouteItinerary(
                          data.item.route_itinerary.id,
                          'pricing_margin',
                          parseFloat($event.target.value)
                        )
                      "
                      type="number"
                      class="form-control d-inline-block min-w-[100px] pt-0 pb-1 h-25 mr-2"
                      v-model.number="data.item.route_itinerary.pricing_margin"
                    />

                    <select
                    :disabled='!isEditable'
                      @change="
                        updateRouteItinerary(
                          data.item.route_itinerary.id,
                          'pricing_margin_unit',
                          $event.target.value
                        )
                      "
                      type="select"
                      class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
                      v-model="data.item.route_itinerary.pricing_margin_unit"
                    >
                      <option disabled>Select Option</option>
                      <option
                        :value="item.value"
                        :key="index"
                        v-for="(item, index) in [
                          { label: 'flat', value: 'flat' },
                          { label: 'percent', value: 'percent' },
                        ]"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="d-flex"
                    v-if="
                      data.item.route_itinerary.pricing_type ===
                      'route_price_table_lookup'
                    "
                  >
                    <div class="mr-2">
                      <select
                      :disabled='!isEditable'
                        class="custom-select custom-select-sm mt-3"
                        v-model="
                          data.item.active_route_price.start_route_bus_stop_id
                        "
                        @change="
                          updateRoutePrice(
                            data.item.route_itinerary.id,
                            data.item.active_route_price,
                            'start_route_bus_stop_id',
                            $event.target.value
                          )
                        "
                      >
                        <option
                          v-for="(b, i) in data.item.route.route_bus_stops"
                          :value="b.id"
                          :key="i"
                        >
                          {{ b.name }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <select
                      :disabled='!isEditable'
                        class="custom-select custom-select-sm mt-3"
                        v-model="
                          data.item.active_route_price.end_route_bus_stop_id
                        "
                        @change="
                          updateRoutePrice(
                            data.item.route_itinerary.id,
                            data.item.active_route_price,
                            'end_route_bus_stop_id',
                            $event.target.value
                          )
                        "
                      >
                        'pricing_margin_unit' + data.item.id
                        <option
                          v-for="(b, i) in data.item.route.route_bus_stops"
                          :value="b.id"
                          :key="i"
                        >
                          {{ b.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </template>

                <template #cell(vehicle_info)="data">
                  <span class="“text-center”">
                    {{ data.item.vehicle.brand }}
                    {{ data.item.vehicle.name }}
                    ({{ data.item.vehicle.registration_number }}) -
                    {{ data.item.vehicle.type }}
                  </span>
                </template>

                <template #cell(selling_price)="data">
                  <div v-if="data.item.active_route_price">
                    <div class="“text-center”">
                      <div>
                        <vue-inline-text-editor
                          :value.sync="data.item.active_route_price.fare"
                          placeholder="Enter amount"
                          :currency-symbol="
                            data.item.active_route_price.fare_currency
                          "
                          @update="
                            updateRouteItineraryPrice(
                              data.item.route_itinerary.id,
                              data.item.active_route_price.id,
                              data.item.active_route_price.fare
                            )
                          "
                          type="currency"
                        >
                          <span slot="edit-label">Edit</span>
                        </vue-inline-text-editor>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        !data.item.route_itinerary.prices?.length &&
                        data.item.route.route_bus_stops?.length
                      "
                    >
                      <vue-inline-text-editor
                        :value.sync="data.item.route_itinerary.default_fare"
                        placeholder="Enter amount"
                        :currency-symbol="
                          data.item.route_itinerary.default_fare_currency
                        "
                        @update="
                          createRouteItineraryPrice(
                            data.item.route_itinerary.id,
                            data.item.route.route_bus_stops[0].id,
                            data.item.route.route_bus_stops[
                              data.item.route.route_bus_stops.length - 1
                            ].id,
                            data.item.route_itinerary.default_fare,
                            data.item.route_itinerary.default_fare_currency
                          )
                        "
                        type="currency"
                      >
                        <span slot="edit-label">Edit</span>
                      </vue-inline-text-editor>
                    </div>
                    <div v-else>
                      <span>No BusStops</span>
                    </div>
                  </div>
                </template>

                <template #cell(created_at)="data">
                  {{ data.value | date('MMMM d, yyyy') }}
                </template>
            
              </b-table>
            </div>

            <div class="card-footer" v-if="totalRecords">
              <div class="row align-items-center">
                <div class="col">
                  <span class="text-dark font-weight-bold">{{
                    tablePageText
                  }}</span>
                </div>
                <div class="col-auto">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecords"
                    :per-page="pageSize"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableView from '@/mixins/tableview'
import VueInlineTextEditor from 'vue-inline-text-editor'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton'

export default {
  components: { VueInlineTextEditor, DownloadButton },
  mixins: [TableView],
  data() {
    return {
      originPartners: [],
      selectedVehicleType: '',
      selectedRouteType: '',
      vehicleTypes: [],
      isEditable: false,
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N',
        },
        {
          key: 'route.route_code',
          label: 'Route name',
        },
        {
          key: 'route_itinerary.trip_time',
          label: 'Itinerary',
        },
        {
          key: 'vehicle_info',
          label: 'Vehicle',
        },
        {
          key: 'cost_of_supply',
          label: 'Cost of supply',
        },
        {
          key: 'selling_price',
          label: 'Selling price',
        },
        {
          key: 'pricing_scheme',
          label: 'Pricing Scheme',
        },
        {
          key: 'pricing_type',
          label: 'Pricing Type',
        },

        {
          key: 'pricing_margin',
          label: 'Pricing',
        },

        // {
        //   key: 'id',
        //   label: 'Action',
        // },
      ],
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      debounce: null,
      partners: [],
      downloadingReport: false,
      fetchingPartners: true,
      search: '',
      filter: {
        range: [],
      },
      statusFilter: 'active',
      universalKey: {},
    }
  },
  created() {
    this.fetchVehicleTypes().finally(() => {
      this.fetchRoutePrices()
    })
  },
  computed: {
    filteredPartners() {
      const search = this.search.toLowerCase()
      let partners = this.partners.filter((partner) => {
        return (
          partner.fname.toLowerCase().includes(search) ||
          partner.lname.toLowerCase().includes(search) ||
          partner.email?.toLowerCase()?.includes(search) ||
          partner.company_name?.toLowerCase()?.includes(search)
        )
      })
      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )
        partners = partners.filter(
          (partner) =>
            partner.created_at.split(' ')[0] >= dateRange[0] &&
            partner.created_at.split(' ')[0] <= dateRange[1]
        )
      }
      return this.partners
    },
    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid() {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    },
  },
  watch: {
    selectedRouteType() {
      this.fetchRoutePrices()
    },
    selectedVehicleType() {
      this.fetchRoutePrices()
    },
    currentPage() {
      this.fetchRoutePrices()
    },
    search(value) {
      if (!value) {
        this.fetchRoutePrices(true)
      }
    },
    statusFilter() {
      this.fetchRoutePrices(true)
    },
  },
  methods: {
    routeBusStopsAfter(routeBusStops, val) {
      if (val == null || !routeBusStops) return routeBusStops
      const busStop = routeBusStops.find((i) => i.id === val)
      if (!busStop) return
      return routeBusStops.filter((v) => {
        return v.position > busStop.position
      })
    },
    async updateRoutePrice(route_itinerary_id, route_price, type, value) {
      const existingItemIndex = this.originPartners.findIndex(
        (item) => item.route_itinerary.id === route_itinerary_id
      )
      if (existingItemIndex < 0) {
        this.$toastr.e('route itinerary not found in the list', 'Error')
        return
      }
      const existingItem = this.originPartners[existingItemIndex]

      if (
        !route_price.start_route_bus_stop_id ||
        !route_price.end_route_bus_stop_id
      ) {
        return
      }

      try {
        const payload = {
          create: [],
          update: [],
        }

        if (!route_price.id) {
          payload.create = [
            {
              start_route_bus_stop_id: route_price.start_route_bus_stop_id,
              end_route_bus_stop_id: route_price.end_route_bus_stop_id,
              fare: route_price.fare,
              fare_currency: route_price.fare_currency,
            },
          ]
        } else {
          payload.update = [
            {
              id: route_price.id,
              start_route_bus_stop_id: route_price.start_route_bus_stop_id,
              end_route_bus_stop_id: route_price.end_route_bus_stop_id,
              fare: route_price.fare,
              fare_currency: route_price.fare_currency,
            },
          ]
        }

        this.loadingRoutePriceUpdate = true
        await this.axios.patch(
          `/v1/route-itineraries/${route_itinerary_id}/price`,
          payload
        )
        this.$toastr.s('Route price updated successfully', 'Success')
      } catch (error) {
        this.$toastr.e(error.message, 'Error')
      } finally {
        this.loadingRoutePriceUpdate = false
      }
    },
    async updateRouteItinerary(routeItineraryId, key, value) {
      const existingItemIndex = this.originPartners.findIndex(
        (item) => item.route_itinerary.id === routeItineraryId
      )
      if (existingItemIndex < 0) {
        this.$toastr.e('route itinerary not found in the list', 'Error')
        return
      }
      const existingItem = this.originPartners[existingItemIndex]

      const payload = {
        [key]: value,
      }
      this.processing = true
      this.axios
        .patch(`/v1/route-itineraries/${routeItineraryId}`, payload)
        .then(() => {})
        .catch((err) => {
          this.partners[existingItemIndex][key] = existingItem[key]
          this.$set(
            this.partners,
            `${existingItemIndex[key]}`,
            existingItem[key]
          )
          this.$toastr.e(`could not update ${key} to ${value}`, 'Error')
        })
    },
    async updateRouteItineraryCostPrice(
      driverId,
      vehicleId,
      routeVehicleId,
      costOfSupply
    ) {
      const existingItemIndex = this.originPartners.findIndex(
        (item) => item.id === routeVehicleId
      )
      if (existingItemIndex < 0) {
        this.$toastr.e('route itinerary not found in the list', 'Error')
        return
      }
      const existingItem = this.originPartners[existingItemIndex]

      const payload = {
        driver_id: driverId,
        cost_of_supply: +costOfSupply,
        vehicle_id: vehicleId,
      }
      this.processing = true
      this.axios
        .patch(`/v1/route-vehicle/${routeVehicleId}`, payload)
        .then(() => {})
        .catch((err) => {
          this.partners[existingItemIndex].cost_of_supply =
            existingItem.cost_of_supply
          this.$set(
            this.partners,
            `${existingItemIndex}.cost_of_supply`,
            existingItem.cost_of_supply
          )
          this.$toastr.e(
            `could not update cost of supply to ${costOfSupply}`,
            'Error'
          )
        })
    },
    async updateRouteItineraryPrice(itineraryId, routePriceId, newPrice) {
      const existingItemIndex = this.originPartners.findIndex(
        (item) => item.route_itinerary_id === itineraryId
      )
      if (existingItemIndex < 0) {
        this.$toastr.e('route itinerary not found in the list', 'Error')
        return
      }
      const existingItem = this.originPartners[existingItemIndex]
      const existingPriceIndex = existingItem.route_itinerary.prices.findIndex(
        (item) => item.id === routePriceId
      )
      if (existingPriceIndex < 0) {
        this.$toastr.e('route price not found in the list', 'Error')
        return
      }
      const existingPrice =
        existingItem.route_itinerary.prices[existingPriceIndex]

      this.axios
        .patch(`/v1/route-itineraries/${itineraryId}/price`, {
          update: [
            {
              id: routePriceId,
              fare:
                typeof newPrice === 'string' ? parseFloat(newPrice) : newPrice,
            },
          ],
        })
        .catch((err) => {
          this.partners[existingItemIndex].route_itinerary.prices[
            existingPriceIndex
          ].fare = existingPrice.fare
          this.$set(
            this.partners,
            `${existingItemIndex}.route_itinerary.prices.${existingPriceIndex}.fare`,
            existingPrice.fare
          )

          this.$toastr.e(`could not update price to ${newPrice}`, 'Error')
        })
    },
    async createRouteItineraryPrice(
      itineraryId,
      originBusStop,
      destinationRouteBusStop,
      newPrice,
      currency
    ) {
      const existingItemIndex = this.originPartners.findIndex(
        (item) => item.route_itinerary_id === itineraryId
      )
      if (existingItemIndex < 0) {
        this.$toastr.e('route itinerary not found in the list', 'Error')
        return
      }
      const existingItem = this.originPartners[existingItemIndex]

      this.axios
        .patch(`/v1/route-itineraries/${itineraryId}/price`, {
          create: [
            {
              start_route_bus_stop_id: originBusStop,
              end_route_bus_stop_id: destinationRouteBusStop,
              fare:
                typeof newPrice === 'string' ? parseFloat(newPrice) : newPrice,
              fare_currency: currency,
            },
          ],
        })
        .then((result) => {
          this.fetchRoutePrices()
        })
        .catch((err) => {
          this.partners[existingItemIndex].route_itinerary.default_fare =
            existingItem.route_itinerary.default_fare
          this.$set(
            this.partners,
            `${existingItemIndex}.route_itinerary.default_fare`,
            existingItem.route_itinerary.default_fare
          )
          this.$toastr.e(`could not update price to ${newPrice}`, 'Error')
        })
    },
    async fetchVehicleTypes() {
      this.axios.get('/v1/vehicle-types').then((res) => {
        this.vehicleTypes = res.data.data
      })
    },
    fetchRoutePrices(reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingPartners = true

      const url = `/v1/route-prices?limit=${this.pageSize}&page=${this.currentPage}`
      const filter_url = `/v1/route-prices?vehicle_type=${this.selectedVehicleType}&route_type=${this.selectedRouteType}&page=${this.currentPage}&limit=${this.pageSize}`
      let request

      if (this.search) {
        request = this.axios.get(url, {
          search: this.search || '',
        })
      } else if (
        this.selectedVehicleType?.length ||
        this.selectedRouteType?.length
      ) {
        request = this.axios.get(filter_url)
      } else {
        request = this.axios.get(url)
      }

      request
        .then((res) => {
          const originPartners = res.data.data
          originPartners.forEach((item) => {
            if (!item.active_route_price) {
              item.active_route_price = {
                start_route_bus_stop_id: null,
                end_route_bus_stop_id: null,
                fare: item.route_itinerary?.default_fare,
                fare_currency:
                  item.route_itinerary?.default_fare_currency || 'NGN',
              }
            }
            if (!item.currency) {
              item.currency = 'NGN'
            }
            item['editable'] = false

          })
          this.originPartners = JSON.parse(JSON.stringify(originPartners))
          this.partners = originPartners
          this.totalRecords = res.data?.metadata?.total
          return this.partners
        })
        .finally(() => (this.fetchingPartners = false))
    },

    showPartnerDetails(data) {
      this.$router.push({
        name: 'PartnerDetails',
        params: {
          vehicleCount: data?.vehicles_count,
          partnerId: data?.id,
          accountSid: data?.account_sid,
        },
      })
    },

    async fetchAllRoutePrices() {
      const params = {
        page: 1,
        perPage: 1,
      }

      let url = `/v1/route-prices?limit=${params.perPage}&page=${params.page}`
      let filter_url = `/v1/route-prices?vehicle_type=${this.selectedVehicleType}&route_type=${this.selectedRouteType}&page=${params.page}&limit=${params.perPage}`
      let request

      if (this.search) {
        request = this.axios.get(url, {
          search: this.search || '',
        })
      } else if (
        this.selectedVehicleType?.length ||
        this.selectedRouteType?.length
      ) {
        request = this.axios.get(filter_url)
      } else {
        request = this.axios.get(url)
      }

      let res = await request
      const totalRecords = res.data?.metadata?.total

      const newParams = {
        page: 1,
        perPage: totalRecords,
      }

      url = `/v1/route-prices?limit=${newParams.perPage}&page=${newParams.page}`
      filter_url = `/v1/route-prices?vehicle_type=${this.selectedVehicleType}&route_type=${this.selectedRouteType}&page=${newParams.page}&limit=${newParams.perPage}`

      if (this.search) {
        request = this.axios.get(url, {
          search: this.search || '',
        })
      } else if (
        this.selectedVehicleType?.length ||
        this.selectedRouteType?.length
      ) {
        request = this.axios.get(filter_url)
      } else {
        request = this.axios.get(url)
      }

      res = await request

      const originPartners = res.data.data
      originPartners.forEach((item) => {
        if (!item.active_route_price) {
          item.active_route_price = {
            start_route_bus_stop_id: null,
            end_route_bus_stop_id: null,
            fare: item.route_itinerary?.default_fare,
            fare_currency: item.route_itinerary?.default_fare_currency || 'NGN',
          }
        }
        if (!item.currency) {
          item.currency = 'NGN'
        }
      })
      const partners = originPartners

      return partners
    },

    busStopName(busStops, busStopId) {
      if (!busStops || !busStopId) return ''

      const item = busStops.find((i) => i.id == busStopId)
      if (!item) return ''
      return item.name
    },
    async downloadReport() {
      if (this.downloadingReport) return

      this.downloadingReport = true

      const tableData = await this.fetchAllRoutePrices()

      const csvData = tableData.map((data) => {
        return {
          route_code: data.route?.route_code,
          itinerary: data.route_itinerary.trip_time,
          vehicle: `${data.vehicle?.brand} ${data.vehicle?.name} ${data.vehicle?.registration_number} - ${data.vehicle?.type}`,
          pricing_scheme: data.route_itinerary.pricing_scheme,
          pricing_type: data.route_itinerary.pricing_type,
          pricing_config:
            data.route_itinerary.pricing_type === 'margin_value_generated'
              ? `${data.route_itinerary.pricing_margin} ${data.route_itinerary.pricing_margin_unit}`
              : data.route_itinerary.pricing_type ===
                  'route_price_table_lookup' && data.active_route_price
              ? `From ${this.busStopName(
                  data.route?.route_bus_stops,
                  data.active_route_price.start_route_bus_stop_id
                )} To ${this.busStopName(
                  data.route?.route_bus_stops,
                  data.active_route_price.end_route_bus_stop_id
                )}`
              : 'N/A',
          cost_of_supply: data.cost_of_supply,
          selling_price_currency: data.active_route_price.fare_currency,
          selling_price: data.active_route_price.fare,
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: 'Route Prices',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Route Prices',
        useTextFile: false,
        useBom: true,
        headers: [
          'Route Code',
          'Itinerary',
          'Vehicle',
          'Pricing Scheme',
          'Pricing Type',
          'Pricing Config',
          'Cost Of Supply',
          'Selling Price Currency',
          'Selling Price',
        ],
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true,
      })
    },
  },
}
</script>

<style lang="scss">
// @use 'src/assets/scss/b-table';
</style>
